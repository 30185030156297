import { render, staticRenderFns } from "./ImgFallback.vue?vue&type=template&id=6729d782&"
import script from "./ImgFallback.vue?vue&type=script&lang=js&"
export * from "./ImgFallback.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6729d782')) {
      api.createRecord('6729d782', component.options)
    } else {
      api.reload('6729d782', component.options)
    }
    module.hot.accept("./ImgFallback.vue?vue&type=template&id=6729d782&", function () {
      api.rerender('6729d782', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8tY4GREefFa0C/JS/comp/ImgFallback.vue"
export default component.exports